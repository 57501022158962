<template>
    <div class="account">
        <div class="account-wrapper">
            <h1>{{ $t('account') }}</h1>

            <div class="details-wrapper acnt">
                <p class="title">{{ $t('account_details') }}</p>

                <div class="details">
                    <div class="item">
                        <p class="label">{{ $t('name') }}</p>
                        <p class="value">{{ user.name }}</p>
                        <router-link to="/update-account">{{ $t('update_account_details') }}</router-link>
                    </div>
                    <div class="item">
                        <p class="label">{{ $t('email_address') }}</p>
                        <p class="value">{{ user.email }}</p>
                    </div>
                    <div class="item">
                        <p class="label">{{ $t('school_org_name') }}</p>
                        <p class="value">{{ user.organization || '-' }}</p>
                    </div>
                    <div class="item">
                        <p class="label">{{ $t('password') }}</p>
                        <p class="value">********</p>
                        <router-link to="/change-password">{{ $t('change_password') }}</router-link>
                    </div>
                    <div class="item">
                        <p class="label">{{ $t('description') }}</p>
                        <p class="value">{{ user.description || '-' }}</p>
                    </div>
                </div>
            </div>

            <div class="details-wrapper">
                <p class="title">{{ $t('plan_details') }}</p>

                <div class="details">
                    <div class="item">
                        <p class="label">{{ $t('plan') }}</p>
                        <p class="value">{{ subscription }}</p>
                        <router-link to="/packages">{{ $t('update_plan_details') }}</router-link>
                    </div>
                    <div class="item">
                        <p class="label">{{ $t('expiration_date') }}</p>
                        <p class="value">{{ user.subscription.end_date | formatDate }}</p>
                        <router-link to="/packages" class="renew">{{ $t('renew_plan') }}</router-link>
                    </div>
                    <!-- <div class="item">
                        <p class="label">Payment</p>
                        <p class="value">•••• •••• •••• 1234</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'

export default {

    computed : {
        ...mapFields(['user']),

        subscription () {
            let { subscription } = this.user
            return subscription.id ? `Personal Plan (${subscription.plan.slice(0,1)} Year/s) ${subscription.takes || 0} takes left.` : 'None'
        }
    }
}
</script>

<style lang="scss" scoped>
.account {
    background: #F3F3F3;
    color: #221E1F;

    .account-wrapper {
        max-width: 1200px;
        padding: 30px 20px;
        margin: auto;
        
        h1 {
            font-family: 'Crimson Pro', sans-serif;
            font-size: 34px;
            font-weight: 900;
            padding-bottom: 24px;
            border-bottom: 1px solid #B8B8B8;
            text-align: center;
        }

        .details-wrapper {
            padding: 35px 0;
            display: flex;
            flex-direction: column;

            @include desktop {
                flex-direction: row;
            }

            &.acnt {
                border-bottom: 1px solid #B8B8B8;
            }

            .title {
                min-width: 185px;
                font-size: 18px;
                font-weight: 600;
                color: #00AEEF;
                text-align: center;
                margin-bottom: 25px;

                @include desktop {
                    margin-right: 140px;
                    font-size: 28px;
                }
            }

            .details {
                flex: 1 1 auto;

                .item {
                    margin-bottom: 17px;
                    position: relative;

                    .label {
                        color: #B8B8B8;
                        margin-bottom: 5px;
                    }

                    .value {
                        font-family: 'Crimson Pro', sans-serif;
                        font-weight: 600;
                    }

                    a {
                        font-family: 'Crimson Pro', sans-serif;
                        font-weight: 600;
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: #00AEEF;
                        cursor: pointer;

                        &.renew {
                            left: 220px;
                            top: 26px;
                        }
                    }
                }
            }
        }
    }
}
</style>